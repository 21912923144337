'use client';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { useNavigate } from '@reshima/navigation-ui';
import { Button } from '@reshima/pure-ui';
import { AppUser, getListsFromServer, upsertLists } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useClientAuth } from '@reshima/client-auth-ui';

export function GettingStartedButton() {
  const name = 'GettingStartedButton';
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState<string>();
  const { createListButton, navigateToMyListButton, navigateToMyListsButton } =
    useTranslations()['home'];
  const navigate = useNavigate();
  const { userLoading, user, anonymouslySignIn } = useClientAuth();

  async function getUser(): Promise<AppUser> {
    const action = 'GetUser';

    const properties = { user: !!user };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const resultUser = user || (await anonymouslySignIn());

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
        properties: {
          ...properties,
          resultUser: !!resultUser,
        },
      });

      return resultUser;
    } catch (error) {
      trackException({
        name,
        action,
        error,
        start,
      });
      throw error;
    }
  }

  async function onClick() {
    const action = Action.Click;
    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    try {
      setLoading(true);

      const user = await getUser();

      const lists = await upsertLists(user);

      const url = lists.length > 1 ? '/lists' : `/list?id=${lists[0].id}`;

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
        properties: {
          listsCount: lists.length,
          url,
        },
      });

      navigate(url);
    } catch (error) {
      trackException({
        name,
        action,
        error,
        start,
      });
      setLoading(false);
    }
  }

  const updateButtonText = useCallback(async () => {
    const action = 'UpdateButtonText';
    const properties = { userLoading, user: !!user };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const lists = user ? await getListsFromServer(user) : [];

      const listsLengthToButtonText: Record<number, string> = {
        0: createListButton,
        1: navigateToMyListButton,
      };

      const buttonText =
        listsLengthToButtonText[lists.length] || navigateToMyListsButton;

      setButtonText(buttonText);

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
        properties: {
          ...properties,
          listsCount: lists.length,
          buttonText,
        },
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        start,
        properties,
      });
    }
  }, [
    createListButton,
    navigateToMyListButton,
    navigateToMyListsButton,
    user,
    userLoading,
  ]);

  useEffect(() => {
    if (userLoading || buttonText) return;
    updateButtonText();
  }, [userLoading, buttonText, updateButtonText]);

  return loading ? (
    <AiOutlineLoading className="text-xl animate-spin" />
  ) : (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      variants={{
        invisible: { opacity: 0, scale: 0.9 },
        visible: { opacity: 1, scale: 1 },
      }}
      animate={buttonText ? 'visible' : 'invisible'}
      transition={{
        type: 'spring',
      }}
    >
      <Button disabled={loading} onClick={onClick}>
        {buttonText || 'placeholder'}
      </Button>
    </motion.div>
  );
}
